import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';

import Breadcrumb from '~components/breadcrumbs';
import Hero from '~components/hero';

const FAQ = () => {
  const intl = useIntl();
  const listingRedirect = useListingRedirect();
  // top message removal
  useLayout({ displayTopCTABar: false, displaySignIn: true });
  
  useSeo(
    intl.formatMessage({
      id: 'containers.faq.seo.title',
    })
  );

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <FormattedMessage id="routes.faq">
                {(msg) => (
                  <Breadcrumb.CrumbLink to={`/${msg}`} index={2}>
                    {intl.formatMessage({
                      id: 'breadcrumbs.faq',
                    })}
                  </Breadcrumb.CrumbLink>
                )}
              </FormattedMessage>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <section className="c-page l-container">
        <Hero.Wrapper>
          <Hero.Title>
            {intl.formatMessage({
              id: 'containers.faq.headline',
            })}
          </Hero.Title>
          <Hero.Intro>
            {intl.formatMessage({
              id: 'containers.faq.introduction',
            })}
          </Hero.Intro>
        </Hero.Wrapper>

        <div className="c-faq l-page-content">
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.faq.sections.first.question',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.faq.sections.first.answer',
              })}
            </div>
          </div>

          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.faq.sections.second.question',
              })}
            </div>
            <div className="c-faq__response">
              {intl.formatMessage({
                id: 'containers.faq.sections.second.answer',
              })}
            </div>
          </div>

          <div className="l-center l-mt2">
            <Link to={listingRedirect} className="c-btn">
              {intl.formatMessage({
                id: 'containers.faq.back-to-homepage',
              })}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
export default FAQ;
